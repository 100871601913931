import {Inject, Injectable} from '@angular/core';
import { Locale } from '@dash/randy/shared/classes/Locale';
import { SpecialLocale } from '../../modules/crud/items/item-value/classes/item-value.model';
import {COLLECTION_LOCALE} from "@dash/randy/shared/shared.module";

@Injectable({
  providedIn: 'root',
})
export class LocaleUtilService {
  constructor(    @Inject(COLLECTION_LOCALE) private collectionLocaleId: string,
  ) {}

  addToLocales(objectLocales: Locale[], newLocale: Locale): Locale[] {
    objectLocales = Object.assign([], objectLocales);
    if (!objectLocales) {
      objectLocales = [];
    }
    let tempLocale = objectLocales.findIndex(locale => locale.locale === newLocale.locale);

    if (tempLocale === -1) {
      objectLocales.push(newLocale);
    } else {
      objectLocales[tempLocale] = newLocale;
    }
    return objectLocales;
  }

  getFromLocales(objectLocales: Locale[], lang?: string): Locale {
    if (!objectLocales || objectLocales.length === 0) {
      return { locale: lang, data: '' };
    }
    if (!objectLocales.find || !objectLocales.find) {
      return null;
    }

    let result = objectLocales.find(locale => locale?.locale === lang);
    if (!lang || !result) {
        result = objectLocales.find(locale => locale?.locale === this.collectionLocaleId);
    }
    if (!result) {
        result = objectLocales.find(locale => locale?.locale);
    }
    if (!result) {
      result = { locale: lang, data: ''}
    }
    return result;
  }

  // todo fix the duplicate code
  addToLocalesSpecial(objectLocales: SpecialLocale[], newLocale: SpecialLocale): SpecialLocale[] {
    objectLocales = Object.assign([], objectLocales);
    if (!objectLocales) {
      objectLocales = [];
    }
    let tempLocale = objectLocales.findIndex(locale => locale.locale === newLocale.locale);

    if (tempLocale === -1) {
      objectLocales.push(newLocale);
    } else {
      objectLocales[tempLocale] = newLocale;
    }
    return objectLocales;
  }

  getFromLocalesSpecial(objectLocales: SpecialLocale[], lang: string): SpecialLocale {
    if (!objectLocales || objectLocales.length === 0) {
      return { locale: lang, data: '' };
    }
    return objectLocales.find(locale => locale.locale === lang);
  }
}
